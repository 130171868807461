import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import { pageData } from "../../pages-content/services/apollo-graphql-consulting";
import {
  HeroContent,
  HeroContentWrapper,
  InlineHeroWithTwoColumnsHeader,
  TwoColumnSectionHeader,
} from "../../components/Services/ServicePageTemplate";
import {
  CenteredContentWrapper,
  ConfettiBigSectionWrapper,
  EmphasizedText,
  SectionHeader,
  Subheading,
  TextBody,
  TextToutFullwidth,
  ThumbnailHeader,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  device,
  Hero,
  TwoColumnsWrapper,
  FlipchartSmallIcon,
  BenefitsSmallIcon,
} from "@xolvio/xolvio-ui";
import {
  Header,
  HeaderBody,
  HeaderContentWrapper,
  Subheader,
} from "./experience-graph-managed-services";
import styled from "styled-components";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { ToutRow } from "../../components/Homepage";
import { contactUsFooterProps } from "../../pages-content/homepage";
import { OtherServices } from "../../components/Services/OtherServices";
import { MoveHack } from "../../components/Services";
import { ListWithIconAndTitle } from "../../components/Products";

const ApolloGraphqlProfessionalServices = () => (
  <LayoutDefault>
    <MoveHack topMobile={"-50px"} />
    <ConfettiBigSectionWrapper
      hideMobileConfetti
      style={{
        backgroundColor: "#ffffff",
        marginBottom: "30px",
        zIndex: 100,
      }}
    >
      <HeaderContentWrapper>
        {pageData.header.icon}
        <Subheader>{pageData.header.subheading}</Subheader>
        <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
          {pageData.header.heading}
        </Header>
        <HeaderBody>{pageData.header.description}</HeaderBody>
      </HeaderContentWrapper>
    </ConfettiBigSectionWrapper>

    <MoveHack top={"80px"} topMobile={"40px"} />

    <Hero
      inline
      style={{ height: "520px" }}
      heading={
        <InlineHeroWithTwoColumnsHeader>
          <HeroSubheading>WHY YOU NEED THIS</HeroSubheading>
          <EmphasizedText
            text={pageData.firstSection.header}
            highlight={pageData.firstSection.headerHighlights}
          />
        </InlineHeroWithTwoColumnsHeader>
      }
      children={
        <HeroContentWrapper>
          <div />

          <HeroContent>
            <TextBody bold>
              It’s not an exaggeration to say GraphQL has transformed the API
              landscape. But the graph concept is so much more than simply a
              REST API replacement.
            </TextBody>
            <TextBody>
              It is actually a new layer in your tech stack and one that unifies
              the representation of all your digital capabilities, services, and
              data. Thanks to GraphQL you can get rid of copious amounts of
              redundant code, move forward quickly with no backend headache, and
              achieve perfect feature consistency across mobile and desktop.
            </TextBody>
          </HeroContent>
        </HeroContentWrapper>
      }
      elementWithBackgroundImage={null}
    />

    <MoveHack top="-200px" topPhone="120px" />

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <img
          style={{ width: "100%", maxWidth: "500px" }}
          src={"/assets/images/apollo_2.svg"}
          width="500px"
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>{pageData.secondSection.subheading}</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={pageData.secondSection.headerHighlights}
              text={pageData.secondSection.header}
            />
          </TwoColumnSectionHeader>
          <Text>{pageData.secondSection.content}</Text>
        </TwoColumnContentNodeWrapper>
      }
    />
    <MoveHack topMobile={"100px"} />

    <CenteredContentWrapper id="whatWeDo">
      <ContentWrapper>
        <Subheading style={{ textAlign: "center" }}>
          {pageData.defaultWhatWeDo.subheading}
        </Subheading>
        <SectionHeader>{pageData.defaultWhatWeDo.sectionHeader}</SectionHeader>
        <AlignToLeftOnMobile>
          {pageData.defaultWhatWeDo.content}
        </AlignToLeftOnMobile>

        <MoveHack top={"100px"} topMobile={"75px"} />
      </ContentWrapper>
    </CenteredContentWrapper>

    <MoveHack top={"-50px"} />

    <CenteredContentWrapper>
      <TwoColumnsWrapper>
        <ListWithIconAndTitle
          header={"Sessions"}
          icon={<FlipchartSmallIcon />}
          list={[
            "Schema storming",
            "Architecture review",
            "SDLC review",
            "Findings review",
            "Audit report presentation",
          ]}
        />

        <ListWithIconAndTitle
          header={"Deliverables"}
          icon={<BenefitsSmallIcon />}
          list={[
            "Audit results & readiness recommendations",
            "Supergraph project roadmap",
            "Governance recommendations",
            "Notes & artifacts from sessions",
          ]}
        />
      </TwoColumnsWrapper>
    </CenteredContentWrapper>

    <MoveHack top={"-100px"} topMobile={"100px"} />

    <AlternateBackground>
      <CenteredContentWrapper id="howWeDoIt">
        <ContentWrapper>
          <Subheading style={{ textAlign: "center" }}>
            {pageData.defaultHowWeDoIt.subheading}
          </Subheading>
          <SectionHeader>
            {pageData.defaultHowWeDoIt.sectionHeader}
          </SectionHeader>
          <AlignToLeftOnMobile>
            {pageData.defaultHowWeDoIt.content}
          </AlignToLeftOnMobile>

          <MoveHack top={"100px"} topMobile={"75px"} />

          <ToutRow>
            {pageData.defaultHowWeDoIt.toutGroup1.map((tout) => (
              <div>
                <ThumbnailHeader style={{ margin: "auto" }}>
                  {tout.title}
                </ThumbnailHeader>
                <TextBody style={{ margin: "auto", textAlign: "left" }}>
                  {tout.body}
                </TextBody>
              </div>
            ))}
          </ToutRow>
          <MoveHack topMobile={"50px"} />
          <ToutRow>
            {pageData.defaultHowWeDoIt.toutGroup2.map((tout) => (
              <div>
                <ThumbnailHeader style={{ margin: "auto" }}>
                  {tout.title}
                </ThumbnailHeader>
                <TextBody style={{ margin: "auto", textAlign: "left" }}>
                  {tout.body}
                </TextBody>
              </div>
            ))}
          </ToutRow>
        </ContentWrapper>
      </CenteredContentWrapper>
    </AlternateBackground>

    <OtherServices services={pageData.otherServices} />

    <TextToutFullwidth {...contactUsFooterProps} />
  </LayoutDefault>
);

export default ApolloGraphqlProfessionalServices;

const sectionSpacingMobile = "80px";
const sectionSpacingDesktop = "180px";

const ServicesWrapper = styled.div`
  > div > div {
    padding-bottom: calc(${sectionSpacingMobile} + 120px);
    margin-bottom: calc(-${sectionSpacingMobile} - 40px);

    @media ${device.tabletVertical} {
      margin-top: calc(${sectionSpacingMobile});
      margin-bottom: calc(-${sectionSpacingMobile} - 90px);
    }

    @media ${device.desktop} {
      margin-bottom: calc(-${sectionSpacingDesktop});
    }
  }

  > div > div > div > div > div > svg {
    height: 100px;
    width: 100px;
  }
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

const AlternateBackground = styled.div`
  background-color: #f4f7f6;
`;

const AlignToLeftOnMobile = styled(TextBody)`
  max-width: 100%;
  text-align: center;

  @media ${device.mobile} {
    text-align: left;
  }
`;

const HeroSubheading = styled(Subheading)`
  text-align: left;
  font-size: 12px;
  @media ${device.tablet} {
    max-width: 700px;
    text-align: left;
    transform: translateY(-15px);
  }
`;
