import React from "react";
import {
  ApolloGraphqlIcon,
  ReadinessAssessmentIcon,
  SupergraphProjectRoadmapIcon,
  SchemaDesignSessionsIcon,
  SystemsModernizationIcon,
  SupergraphIcon,
  IntegratingIcon,
  ReferenceRepositoriesIcon,
  MaintenanceIcon,
} from "@xolvio/xolvio-ui";

export const pageData = {
  header: {
    subheading: "SERVICES / DIGITAL EXPERIENCE INTEGRATION",
    heading: "Apollo GraphQL Consulting",
    headingHighlights: "Professional",
    description:
      "GraphQL consulting & development from the official Apollo GraphQL partner.",
    icon: <SchemaDesignSessionsIcon height="100px" width="100px" />,
    url: "/services/apollo-graphql-consulting",
    sectionId: "test",
  },

  firstSection: {
    subheading: "WHY YOU NEED THIS",
    header: "Revolutionary API technology ",
    headerHighlights: "Revolutionary",
    content:
      "It’s not an exaggeration to say GraphQL has transformed the API landscape. But the graph concept is so much more than simply a REST API replacement. It is actually a new layer in your tech stack and one that unifies the representation of all your digital capabilities, services, and data. Thanks to GraphQL you can get rid of copious amounts of redundant code, move forward quickly with no backend headache, and achieve perfect feature consistency across mobile and desktop.",
  },

  secondSection: {
    subheading: "WHY US",
    header: "Get absolute confidence in your GraphQL API",
    headerHighlights: "confidence",
    content:
      "Apollo GraphQL is leading the charge of the API revolution and has developed the best-in-class tooling. Xolvio has been part of their journey since the very beginning, so we well earned the title of the sole official Apollo GraphQL professional services partner. Whether you need to jumpstart into GraphQL from scratch, break up your existing monolith, or scale up your supergraph, we’re here to help.",
  },
  thirdSection: {
    subheading: "HOW WE DO IT",
    header: "Apollo GraphQL professional services and beyond",
    headerHighlights: "professional",
    content:
      "We help in all areas related to Apollo GraphQL and surrounding technologies including Apollo Federation, schema management, one-graph unification, Domain Driven Design, microservice deployment, automated testing, performance tuning, security, and integrating with React. However, thanks to our holistic approach to technology, we also help with organization-wide processes and enterprise architecture decisions aiming to improve the speed and quality of your software delivery, while using GraphQL as the transport layer.",
  },

  otherServices: [
    "Experience Graph Managed Services",
    "GraphQL Security Review & Remediation",
    "SDLC & Architecture Consulting",
    "Rapid Backend Development",
    "Legacy Systems Modernization",
  ],
  contactUsUrl: "/contact-us/?subject=apollo-graphql-consulting",
  defaultWhatWeDo: {
    subheading: "WHAT WE DO",
    sectionHeader: "3-day GraphQL consulting package",
    content:
      "We want to ensure the success of your Supergraph initiative. Armed with enterprise-grade experience in Apollo GraphQL consulting and development from working with the likes of Audi, Wayfair, T-Mobile, and HPE, we devised a lightweight consulting offering to help you accelerate and scale GraphQL adoption.",
    toutGroup1: [
      {
        title: "Stage 1: Assessment & Enablement",
        body: "Following a thorough assessment of your organization’s situation and needs, we secure stakeholder alignment and create your tailor-made Supergraph Enablement Plan: an implementation project roadmap.",
      },
      {
        title: "Stage 2: Supergraph Jumpstart",
        body: "We launch your implementation as per the Supergraph Enablement Plan. Our dedicated teams of experienced engineers will collaborate with your team as part of a timeboxed service.",
      },
      {
        title: "Stage 3: Supergraph Growth",
        body: "With your supergraph in production, we continue to evolve it by adding or refactoring subgraphs, as well as help with governance and tooling. Beyond the graph itself, we improve your cloud infrastructure and automation quality practices.",
      },
    ],
  },
  selectedDeliverablesProps: {
    title: "Selected Deliverables",
    subheading: ["APOLLO GRAPHQL", "PROFESSIONAL SERVICES"],
    sections: [
      {
        heading: "Readiness assessment scorecard",
        description: "",
        icon: <ReadinessAssessmentIcon />,
        sectionId: "services",
      },
      {
        heading: "Supergraph project roadmap",
        description: "",
        icon: <SupergraphProjectRoadmapIcon />,
        sectionId: "services",
      },
      {
        heading: "Conducting schema design sessions",
        description: "",
        icon: <SchemaDesignSessionsIcon />,
        sectionId: "services",
      },
      {
        heading: "Breaking monoliths into microservices",
        description: "",
        icon: <SystemsModernizationIcon />,
        sectionId: "services",
      },
      {
        heading: "Implementation of a federated supergraph",
        description: "",
        icon: <ApolloGraphqlIcon />,
        sectionId: "services",
      },
      {
        heading: "Joining existing REST services to the supergraph",
        description: "",
        icon: <SupergraphIcon />,
        sectionId: "services",
      },
      {
        heading: "Integrating CI/CD pipelines with schema registry",
        description: "",
        icon: <IntegratingIcon />,
        sectionId: "services",
      },
      {
        heading:
          "Creating standard reference repositories with best practice patterns",
        description: "",
        icon: <ReferenceRepositoriesIcon />,
        sectionId: "services",
      },
      {
        heading: "Defining maintenance strategies",
        description: "",
        icon: <MaintenanceIcon />,
        sectionId: "services",
      },
    ],
  },
  defaultHowWeDoIt: {
    subheading: "HOW WE DO IT",
    sectionHeader: "Apollo GraphQL professional services and beyond",
    content:
      "We help in all areas related to Apollo GraphQL and surrounding technologies including Apollo Federation, schema management, one-graph unification, Domain Driven Design, µService deployment, automated testing, performance tuning, security, and integrating with React. However, thanks to our holistic approach to technology, we also help with organization-wide processes and enterprise architecture decisions aiming to improve the speed and quality of your software delivery, while using GraphQL as the transport layer.",
    toutGroup1: [
      {
        title: "Targeted approach",
        body: "GraphQL can be both exciting and daunting as any new technology tends to be. We take care of the daunting part by providing you expertise exactly where you need it.",
      },
      {
        title: "Harmonious integration",
        body: "Leverage our best practices around building new microservices, as well as integrating with existing legacy services and data APIs.",
      },
      {
        title: "Domain mapping",
        body: "We expertly run event storming sessions that help your team figure out the business domains which naturally map to better graphs.",
      },
    ],
    toutGroup2: [
      {
        title: "Evolutionary design",
        body: "Decouple your monoliths using domain driven design techniques to facilitate the build of an evolving schema.",
      },
      {
        title: "Up your delivery game",
        body: "We help you with moving to Continuous Integration/Continuous Deployment best practices while using GraphQL.",
      },
      {
        title: "Let us build for you",
        body: "Augment your team with our expert builders for as long as you need. We provide your organization with expert GraphQL development for the long haul.",
      },
    ],
  },
};
